import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import ListenPodcast from 'src/components/ListenPodcast'
import SocialShare from 'src/components/SocialShare'
import SharePanel from 'src/components/SharePanel'
import { getBaseUrl } from 'src/utils'
import Ad from '../Ad'
import PropTypes from 'prop-types'
import styles from './Interview.module.scss'
import { isOnline, removeListeners } from 'src/utils'
import { PodcastContext } from 'src/components/PodcastLayout'
import { FormattedMessage } from 'react-intl'

class Interview extends Component {
  state = { isModalOpen: false, isOnline: true }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.document.body.classList.add('white-bg')
    }
    isOnline(this)
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.document.body.classList.remove('white-bg')
    }
    removeListeners()
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  render() {
    const {
      className,
      interview: {
        photographer,
        expertComments,
        author,
        summary,
        podcast,
        slug,
        title,
        bodyNode: {
          childMarkdownRemark: { html },
        },
      },
      ad,
      locale,
    } = this.props

    const newSummary = summary.filter(({ title, description }) => title && description)

    const str = '</h5>'
    let indexEndLead = html.lastIndexOf(str)
    if (indexEndLead !== -1) {
      indexEndLead += str.length
    }
    const htmlBeforeVideo = html.substring(0, indexEndLead)
    const htmlAfterVideo = html.substring(indexEndLead)

    const buttonContainer = (
      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={this.toggleModal}>
          <span className={styles.buttonText}>
            <FormattedMessage id="home.share" />
          </span>
          <SocialShare
            className={styles.share}
            isWhite={true}
            title={title}
            url={`${getBaseUrl()}${locale}/interview/${slug}`}
            isModalOpen={this.state.isModalOpen}
            toggleModal={this.toggleModal}
          />
        </button>

        {podcast.length > 0 &&
          podcast[0] && (
            <PodcastContext.Consumer>
              {(contextValue) => {
                const { onChangeData } = contextValue
                return (
                  <button
                    className={styles.button}
                    onClick={
                      podcast.length > 0 && podcast[0]
                        ? (e) => {
                            e.preventDefault()
                            onChangeData(
                              podcast[0].podcast.url,
                              podcast[0].podcastTitle,
                              podcast[0].podcastGuest,
                              podcast[0].podcastCover,
                              slug,
                            )
                          }
                        : () => console.log('Something went wrong!')
                    }
                  >
                    <ListenPodcast
                      style={{ position: 'unset' }}
                      podcast={podcast.length > 0 && podcast[0]}
                      parentSlug={slug}
                      locale={locale}
                    />
                  </button>
                )
              }}
            </PodcastContext.Consumer>
          )}
      </div>
    )
    return (
      <div className={classNames(className, styles.root)}>
        <SharePanel title={title} url={`${getBaseUrl()}${locale}/interview/${slug}`} />

        <div className={styles.leftColumn}>
          <div className={styles.articlesWrapper}>
            <article className={styles.article} dangerouslySetInnerHTML={{ __html: htmlBeforeVideo }} />
            <article className={styles.article} dangerouslySetInnerHTML={{ __html: htmlAfterVideo }} />
          </div>
          {expertComments &&
            expertComments.map(({ html, expert, partner }) => (
              <div className={styles.review} key={expert.title}>
                <div className={styles.expert}>
                  <div className={styles.expertAbout}>
                    {expert.photo && <img src={expert.photo.publicURL} alt="expert" />}
                  </div>
                  <div>
                    <span className={styles.expertName}>
                      <OutboundLink href={expert.url}>{expert.title}</OutboundLink>
                    </span>
                    <span className={styles.expertBio}>{expert.about}</span>
                  </div>
                </div>

                <article className={styles.reviewArticle}>
                  <p dangerouslySetInnerHTML={{ __html: html }} />
                </article>
              </div>
            ))}
          {newSummary.length > 0 && (
            <div className={styles.summary}>
              <h1>
                <span>
                  <FormattedMessage id="home.summary" />:
                </span>
              </h1>
              <br />
              {newSummary.map((item) => (
                <Fragment key={item.title}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </Fragment>
              ))}
            </div>
          )}
          <div className={styles.meta}>
            {author && (
              <div className={styles.author}>
                <strong>
                  <span>
                    {' '}
                    <FormattedMessage id="home.interlocutor" />
                  </span>
                </strong>
                <span>{author}</span>
              </div>
            )}
            {photographer && (
              <div className={styles.photographer}>
                <strong>
                  <span>
                    <FormattedMessage id="home.photo" />
                  </span>
                </strong>
                <span>{photographer}</span>
              </div>
            )}
          </div>
          {this.state.isOnline ? buttonContainer : <div className={styles.offlineMode}>{buttonContainer}</div>}
        </div>

        <div className={styles.ad}>
          <Ad locale={locale} ad={ad} />
        </div>
      </div>
    )
  }
}

Interview.propTypes = {
  className: PropTypes.string,
  interview: PropTypes.shape({
    description: PropTypes.string,
    podcast: PropTypes.array,
    podcastCover: PropTypes.object,
    podcastGuest: PropTypes.string,
    podcastTitle: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    html: PropTypes.string,
    photographer: PropTypes.string,
    expertComments: PropTypes.array,
    author: PropTypes.string,
    summary: PropTypes.array,
  }),
  locale: PropTypes.string.isRequired,
}

export default Interview
