import React from 'react'
import styles from './SharePanel.module.scss'
import classNames from 'classnames'
import { FacebookShareButton, LinkedinShareButton, TelegramShareButton } from 'react-share'
import FacebookShare from 'src/components/svg/FacebookShare'
import LinkedInShare from 'src/components/svg/LinkedinShare'
import TelegramShare from 'src/components/svg/TelegramShare'

class SharePanel extends React.Component {
  render() {
    const { url, title, className } = this.props
    return (
      <div className={classNames(className, styles.buttons)}>
        <FacebookShareButton url={url} quote={title} className={styles.shareButton}>
          <FacebookShare width="50px" height="50px" />
        </FacebookShareButton>

        <LinkedinShareButton url={url} quote={title} className={styles.shareButton}>
          <LinkedInShare width="50px" height="50px" />
        </LinkedinShareButton>

        <TelegramShareButton url={url} title={title} className={styles.shareButton}>
          <TelegramShare width="50px" height="50px" />
        </TelegramShareButton>
      </div>
    )
  }
}

export default SharePanel
