import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ListenPodcast from 'src/components/ListenPodcast'
import styles from './Cover.module.scss'
import Img from 'gatsby-image'
import { isOnline, removeListeners } from 'src/utils'

const podcastStyle = { position: 'relative', top: '0', left: '0' }

class Cover extends Component {
  state = { isOnline: true }

  componentDidMount() {
    isOnline(this)
  }

  componentWillUnmount() {
    removeListeners()
  }

  render() {
    const { className, image, description, title, podcast, locale, templateKey, slug } = this.props

    const podcastEl = (
      <ListenPodcast
        style={podcastStyle}
        podcast={podcast.length > 0 && podcast[0]}
        locale={locale}
        parentSlug={slug}
        parentTemplateKey={templateKey}
      />
    )

    return (
      <section className={classNames(className, styles.root)}>
        <figure className={styles.backgroundImage}>
          <Img fluid={image.fluid} />
        </figure>
        <div className={styles.entry}>
          <div className={styles.inner}>
            <div style={{ position: 'relative' }}>
              <h1>{title}</h1>
              {description && (
                <h2>
                  {description && description.length >= 200 ? `${description.substring(0, 200)}...` : description}
                </h2>
              )}
            </div>
            {podcast.length > 0 &&
              podcast[0] &&
              (this.state.isOnline ? podcastEl : <div className={styles.offlineMode}>{podcastEl}</div>)}
          </div>
        </div>
      </section>
    )
  }
}

Cover.defaultProps = {
  podcast: [],
}

Cover.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  podcast: PropTypes.array,
  locale: PropTypes.string.isRequired,
}

export default Cover
