import React, { Component } from 'react'
import classNames from 'classnames'
import Swiper from 'react-id-swiper'
import styles from './Ad.module.scss'

class Ad extends Component {
  render() {
    const {
      ad: { edges },
      isOnHomePage,
    } = this.props

    const params = {
      slidesPerView: 1,
      loop: true,
      containerClass: 'custom-container',
      effect: 'fade',
      simulateTouch: false,
      autoplay: {
        delay: Number(edges[0].node.autoplayDelay),
      },
    }

    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <Swiper {...params}>
            {edges[0].node.slides.map(({ id, link, image: { url } }) => (
              <div key={id}>
                {/* eslint-disable-next-line */}
                <a
                  className={classNames(styles.content, isOnHomePage && styles.onHomePage)}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link}
                  style={{ backgroundImage: `url(${url}?auto=compress,format)` }}
                />
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    )
  }
}

export default Ad
